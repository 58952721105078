import screenshots from '../img/papperoger-user.png'
import googleplay from '../img/Google_Play.svg'
import appstore from '../img/app-store-available.png'

export const MobileApp = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>Mobile App Available Now!</h2>
        </div>
        <div className='row'>
          <img src={screenshots} style={{ width: '90%' }} />
          <div style={{ marginTop: 40 }}>
            <a href={'https://apps.apple.com/app/id1620418003'}>
              <img src={appstore} style={{ width: '20%' }} />
            </a>
            <a href={'https://play.google.com/store/apps/details?id=com.papperoger'}>
              <img src={googleplay} style={{ width: '20%', marginLeft: 20 }} />
            </a>
          </div>
          {/* {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-6 col-md-3'>
                  {' '}
                  <i className={d.icon}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'} */}
        </div>
      </div>
    </div>
  )
}
