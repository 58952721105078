import { useState } from 'react'
import emailjs, { init } from 'emailjs-com'
import { useNavigate } from 'react-router-dom';
const EMAIL_JS_KEY = process.env.REACT_APP_EMAIL_JS_KEY;
init(EMAIL_JS_KEY);

export const Contact = (props) => {
  const navigate = useNavigate()
  // const [sender, setSender] = useState('')
  // const [email, setEmail] = useState('')
  // const [message, setMessage] = useState('')

  // const handleChange = (e) => {
  //   const { name, value } = e.target
  //   switch (name) {
  //     case sender:
  //       setSender(value);
  //       break;
  //     case email:
  //       setEmail(value);
  //       break;
  //     case message:
  //       setMessage(value);
  //       break;
  //     default:
  //       break;
  //   }
  // }

  // const clearState = () => {
  //   setSender('');
  //   setEmail('');
  //   setMessage('');
  // }

  // const handleSubmit = (e) => {
  //   e.preventDefault()
  //   console.log(message)
  //   console.log(e.target.name.name)
  //   // emailjs
  //   //   .sendForm(
  //   //     'service_zfb31x8', 'template_ggwiq38', e.target
  //   //   )
  //   //   .then(
  //   //     (result) => {
  //   //       console.log(result.text)
  //   //       clearState()
  //   //     },
  //   //     (error) => {
  //   //       console.log(error.text)
  //   //     }
  //   //   )
  // }

  const initialState = {
    name: '',
    email: '',
    message: ''
  }

  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ initialState })

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!name || !email || !message) return alert('Please fill in all the fields')

    alert('Your message has been sent successfully!')
    // console.log(name, email, message)
    // emailjs
    //   .send(
    //     'service_zfb31x8', 'template_ggwiq38', {
    //       from_name: name,
    //       to_name: "Mussan Team",
    //       message: message,
    //       reply_to: email,
    //       }
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text)
    //       clearState()
    //     },
    //     (error) => {
    //       console.log(error.text)
    //     }
    //   )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-9'>
            <div className='row'>
              <div className='section-title'>
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form>
            </div>
          </div>
          {/* <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Address
                </span>
                {props.data ? props.data.address : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-phone'></i> Phone
                </span>{' '}
                {props.data ? props.data.phone : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> Email
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div> */}
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    {/* <a href="/"> */}
                    <i className='fa fa-facebook'></i>
                    {/* </a> */}
                  </li>
                  <li>
                    {/* <a href="/"> */}
                    <i className='fa fa-instagram'></i>
                    {/* </a> */}
                  </li>
                  <li>
                    {/* <a href="/"> */}
                    <i className='fa fa-twitter'></i>
                    {/* </a> */}
                  </li>
                  <li>
                    {/* <a href="/"> */}
                    <i className='fa fa-youtube'></i>
                    {/* </a> */}
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='row'>
                <div className='social'>
                  <div className='col-lg-6 col-sm-6 col-xs-12'>
                    <a href='/privacy-policy.html' rel='nofollow' style={{ color: 'white' }}  >
                      Privacy Policy
                    </a>
                  </div>
                  <div className='col-lg-6 col-sm-6 col-xs-12'>
                    <a href='/terms-conditions.html' rel='nofollow' style={{ color: 'white' }} >
                      Terms & Conditions
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2023 Papperoger. Design by{' '}
            <a href='#' rel='nofollow'>
              Papperoger Technology
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
