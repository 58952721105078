import { Route, Routes, redirect } from 'react-router-dom'
import Home from './pages/Home'
// import PrivacyPolicy from './pages/PrivacyPolicy'
// import TermsConditions from './pages/TermsConditions'

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />}></Route>
      {/* <Route path='/privacy'>
        <redirect to={'/privacy-policy.html'} />
      </Route> */}
      {/* <Route path='/terms' element={<TermsConditions />}></Route> */}
    </Routes>
  )
}

export default App